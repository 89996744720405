import React, { useEffect, useState } from "react";
import styles from "../../styles/modular css/Finance.module.css";

//import components
import Tiles from "../general/cards/publicProfile/Tiles";
import { getMethod } from "../../utils/apiMethod";
import { toastError } from "../../utils/Toasters";
import { StateContext } from "../Context/StateContext";
import { useContext } from "react";

function Finance({ companyId }) {
  const { setLoading } = useContext(StateContext);
  const [financeDetails, setFinanceDetails] = useState({
    ipoStatus: "-",
    latestFundingRound: "-",
    latestValuation: 0,
    annualRevenue: 0,
    totalFunding: 0,
    financeLink: "",
  });

  const getFormattedNumber = (number) => {
    // Check if the input is a string and already contains commas
    if (typeof number === "string" && number.includes(",")) {
      return number;
    }

    // Ensure the input is a number, even if it's passed as a string
    const num = Number(number);

    // Format the number with commas and ensure two decimal places
    const formattedNumber = num.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formattedNumber;
  };

  useEffect(() => {
    setLoading(true);
    getMethod(`users/viewCompanyFinance/${companyId}`)
      .then((res) => {
        if (res?.status == true) {
          const updatedFinanceDetails = {
            ...financeDetails,
            ipoStatus: res.data?.ipoStatus,
            latestFundingRound: res.data?.latestFundingRound,
            latestValuation: res.data?.latestValuation,
            annualRevenue: res.data?.annualRevenue,
            totalFunding: res.data?.totalFunding,
            financeLink: res?.data?.financeLink,
          };
          setFinanceDetails(updatedFinanceDetails);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
      });
  }, []);

  return (
    <div className="d-flex d-flex-column bg-light-blue rounded-16 p-6 gap-2">
      {/* Heading text */}
      <div>
        <h1 className="text-blue">Finance</h1>
      </div>

      {/* Tiles */}
      <div className={`${styles.financeCardGrid}`}>
        <Tiles
          image={"/assets/IpoStatus.svg"}
          heading={"IPO Status"}
          content={financeDetails?.ipoStatus}
          type={"finance"}
          bg={"rgba(248, 251, 228, 1)"}
        />
        {financeDetails?.financeLink &&
          financeDetails?.ipoStatus == "public" && (
            <Tiles
              image={"/assets/AnnualRevenue.svg"}
              heading={"Yahoo Finance Link"}
              content={financeDetails?.financeLink}
              type={"finance"}
              bg={"rgba(248, 251, 228, 1)"}
            />
          )}

        <Tiles
          image={"/assets/LatestFundingRound.svg"}
          heading={"Latest Funding Round"}
          content={financeDetails?.latestFundingRound}
          type={"finance"}
          bg={"rgba(228, 251, 249, 1)"}
        />
        <Tiles
          image={"/assets/TotalFunding.svg"}
          heading={"Total Funding"}
          content={
            financeDetails.totalFunding &&
            getFormattedNumber(financeDetails.totalFunding)
          }
          dollar={`${financeDetails?.totalFunding && "$"}`}
          type={"finance"}
          bg={"rgba(228, 237, 251, 1)"}
        />

        <Tiles
          image={"/assets/LatestValuation.svg"}
          heading={"Latest Valuation"}
          content={
            financeDetails?.latestValuation &&
            getFormattedNumber(financeDetails?.latestValuation)
          }
          type={"finance"}
          dollar={`${financeDetails.latestValuation && "$"}`}
          bg={"rgba(244, 228, 251, 1)"}
        />
        <Tiles
          image={"/assets/AnnualRevenue.svg"}
          heading={"Annual Revenue"}
          content={financeDetails?.annualRevenue}
          type={"finance"}
          bg={"rgba(234, 251, 228, 1)"}
        />
      </div>
    </div>
  );
}

export default Finance;
