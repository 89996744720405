function ConfirmationModal({ onYes, onNo}) {

    const modalStyle =  {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: "1000",
     }
    
  return (
    <div className="modal-wrapper" onClick={()=>onNo()}>

    <div className='d-flex bg-white col-11 col-sm-8 col-md-6 col-lg-5 col-xl-4 rounded-20 box-shadow1 m-auto' style={modalStyle}>
        <div className="d-flex d-flex-column d-align-center gap-3 m-5 col-12">

                <div className="d-flex d-flex-column d-align-center">
                    <h3>Are you sure?</h3>
                    <div className="font-20 text-grey-4 f-400">You want to unfollow this company</div>
                </div>
                <div className="d-flex gap-2 col-12">

                    <div className="col-6">
                        <button  className="btn btn-aqua-thin col-12"
                            onClick={()=>onYes()}>
                                Yes
                        </button>
                        
                        
                    </div>
                    <div className="col-6">
                        <button onClick={(e)=>{
                            onNo();
                        }} className="btn btn-red-thin col-12">No</button>
                    </div>
                   

                </div>

        </div>

    </div>
    </div>
  );
}

export default ConfirmationModal;
