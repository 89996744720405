import React from "react";
import FollowingCompaniesList from "../../FollowingCompaniesList";

function FollowingCompanyModal({ companiesFollowed, closeHandler }) {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1000",
    height: "auto",
  };
  return (
    <div className="modal-wrapper">
      <div
        className="d-flex d-flex-column gap-4 bg-white col-11 col-sm-11 col-md-9 col-lg-7 col-xl-6 col-xxl-5 rounded-20 box-shadow1 m-auto p-6"
        style={modalStyle}
      >
        <div className="d-flex d-align-center d-justify-space-between">
          <h3 className="text-aqua">
            Followings ({companiesFollowed?.length})
          </h3>
          <svg
            onClick={() => closeHandler()}
            className="cursor-pointer"
            width="33"
            height="33"
            viewBox="0 0 33 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.39014 23.0951L16.2421 16.2432L23.094 23.0951M23.094 9.39124L16.2407 16.2432L9.39014 9.39124"
              stroke="#364B69"
              stroke-width="1.9603"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <FollowingCompaniesList companiesFollowed={companiesFollowed} fromModal={true}/>
      </div>
    </div>
  );
}

export default FollowingCompanyModal;
