import React, { useEffect, useState } from "react";

//import components
import Awards from "../general/cards/publicProfile/Awards";
import Founders from "../general/cards/publicProfile/Founders";
import Executive from "../general/cards/publicProfile/ExecutiveLeadership";
import { toastError } from "../../utils/Toasters";
import { getMethod } from "../../utils/apiMethod";
import SpinLoader from "../general/SpinLoader";
import styles from "../../styles/modular css/People.module.css"

function People({ companyId }) {
  const [loading, setLoading] = useState(false);

  const [advisors, setAdvisors] = useState([]);
  const [executives, setExecutives] = useState([]);
  const [boardMembers, setBoardMembers] = useState([]);
  const [investors, setInvestors] = useState([]);

  useEffect(() => {
    setLoading(true);
    getMethod(`users/viewCompanyPeople/${companyId}`)
      .then((res) => {
        setLoading(false);
        if (res?.status) {
          const foundersData = res?.data
            .filter((person) => person.label === "Advisors")
            .map((person) => person.people);
          const executivesData = res?.data
            .filter((person) => person.label === "Executive Leadership")
            .map((person) => person.people);
          const boardMembersData = res?.data
            .filter((person) => person.label === "Board Members")
            .map((person) => person.people);
          const investorsData = res?.data
            .filter((person) => person.label === "Primary Investors")
            .map((person) => person.people);

          setAdvisors(foundersData.flat());
          setExecutives(executivesData.flat());
          setBoardMembers(boardMembersData.flat());
          setInvestors(investorsData.flat());
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
      });
  }, []);

  return (
    <div className="d-flex d-flex-column bg-light-blue rounded-16 p-6 gap-2">
      {/* EXECUTIVE LEADERSHIP */}

      {executives.length > 0 && (
        <div className="d-flex d-flex-column gap-2">
          {/* Heading text */}
          <div>
            <h1 className="text-blue font-20">Executive Leadership</h1>
          </div>

          {/* Tiles */}
          {loading ? (
            <div className="m-10">
              <SpinLoader />
            </div>
          ) : (
            <div className={`${styles.peopleCardGrid} d-grid grid-col-3 gap-3`}>
              {executives.map((executive, index) => {
                return (
                  <Executive
                    name={executive.name}
                    title={executive.title}
                    imageUrl={executive.imageUrl}
                    index={index}
                    linkedinUrl={executive.linkedinUrl}
                  />
                );
              })}
            </div>
          )}
        </div>
      )}

      {/* PRIMARY INVESTORS */}
      {investors.length > 0 && (
        <div className="d-flex d-flex-column gap-2">
          {/* Heading text */}
          <div>
            <h1 className="text-blue font-20">Primary Investors</h1>
          </div>

          {/* Tiles */}

          {loading ? (
            <div className="m-10">
              <SpinLoader />
            </div>
          ) : (
            <div
              className={`${styles.investorsCardGrid} d-grid grid-col-4 gap-3`}
            >
              {investors.map((investor, index) => {
                return (
                  <Awards
                    brandName={investor.name}
                    brandImage={investor.imageUrl}
                  />
                );
              })}
            </div>
          )}
        </div>
      )}

      {/* BOARD MEMBER */}
      {boardMembers.length > 0 && (
        <div className="d-flex d-flex-column gap-2">
          {/* Heading text */}
          <div>
            <h1 className="text-blue font-20">Board Members</h1>
          </div>

          {/* Tiles */}
          {loading ? (
            <div className="m-10">
              <SpinLoader />
            </div>
          ) : (
            <div className={`${styles.peopleCardGrid} d-grid grid-col-3 gap-3`}>
              {boardMembers.map((member, index) => {
                return (
                  <Executive
                    name={member.name}
                    title={member.title}
                    imageUrl={member.imageUrl}
                    index={index}
                    linkedinUrl={member.linkedinUrl}
                  />
                );
              })}
            </div>
          )}
        </div>
      )}

      {/* FOUNDERS */}
      {advisors.length > 0 && (
        <div className="d-flex d-flex-column gap-2">
          {/* Heading text */}
          <div>
            <h1 className="text-blue font-20">Advisors</h1>
          </div>

          {/* Tiles */}
          {loading ? (
            <div className="m-10">
              <SpinLoader />
            </div>
          ) : (
            <div className={`${styles.peopleCardGrid} d-grid grid-col-3 gap-3`}>
              {advisors.map((founder, index) => {
                return (
                  <Executive
                    name={founder.name}
                    title={founder.title}
                    imageUrl={founder.imageUrl}
                    index={index}
                    linkedinUrl={founder.linkedinUrl}
                  />
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default People;
