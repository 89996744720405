import React, { useEffect, useState, useRef } from "react";
import { PulseLoader } from "react-spinners";
import { getMethod } from "../../../../utils/apiMethod";

function useOutsideAlerter(ref, handler) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, handler]);
}

function SelectProductTypeModal({
  title,
  prodTypeDetails,
  fetchSelectedOption,
  placeholder,
  width,
  isRequired,
}) {
  const [keyword, setKeyword] = useState("");
  const [debouncedKeyword, setDebouncedKeyword] = useState(keyword); // Debounced keyword state

  const [loading, setLoading] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [openAddNew, setOpenAddNew] = useState(false);
  const [openEditExisting, setEditExisting] = useState(false);
  const [productTypes, setProductTypes] = useState([]);

  const wrapperRef = useRef(null);
  const openHandler = () => setOpenDropdown((prev) => !prev);
  useOutsideAlerter(wrapperRef, openHandler);

  // Debounce logic
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedKeyword(keyword);
    }, 500); // Adjust the delay as needed (e.g., 500ms)

    return () => {
      clearTimeout(handler);
    };
  }, [keyword]);

  useEffect(() => {
    if (openDropdown) {
      setLoading(true);
      getMethod(`users/getMarketCategories?keyword=${debouncedKeyword}`)
        .then((res) => {
          if (res?.status) {
            setProductTypes(res.data);
          }
          if (res.status == 402) {
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [openDropdown, debouncedKeyword]); // Use debounced keyword

  return (
    <div className={`col-12 d-flex d-flex-column gap-1`}>
      <div className="p-relative d-flex d-flex-column gap-4">
        <div
          onClick={() => {
            if (openAddNew) {
              setOpenAddNew(false);
              setOpenDropdown(false);
            } else if (openEditExisting) {
              setEditExisting(false);
              setOpenDropdown(false);
            } else {
              setOpenDropdown(!openDropdown);
            }
          }}
          style={{ backgroundColor: "#EDF2F1" }}
          className="d-flex d-justify-space-between gap-1 input-field-solid rounded pl-4 o-hidden"
        >
          <input
            style={{ backgroundColor: "#EDF2F1" }}
            className="col-12 input-field-solid border-none outline-none"
            placeholder="Market Category"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
        </div>

        {openDropdown && (
          <div
            ref={wrapperRef}
            style={{ top: "56px", height: "200px", zIndex: "1" }}
            className="p-absolute bg-white box-shadow2 rounded d-flex d-flex-column gap-1 f-500 pl-1 pr-1 pt-1 pb-1 w-full o-hidden oy-scroll"
          >
            {loading ? (
              <div className="h-full d-flex d-align-center d-justify-center">
                <PulseLoader size={15} color="#1CBAAB" loading={loading} />
              </div>
            ) : (
              <div className="h-full oy-scroll">
                <div className="d-flex d-flex-column">
                  {productTypes.map((option, index) => (
                    <div
                      key={index}
                      className="cursor-pointer d-flex gap-2 d-align-center p-1 dropdown-items"
                      onClick={() => {
                        let newData = [...selectedOption, option];

                        let filtered = selectedOption?.filter(
                          (existing) => existing?.name === option?.name
                        );

                        if (filtered.length === 0) {
                          setSelectedOption((prevSelectedOption) => [
                            ...prevSelectedOption,
                            option,
                          ]);

                          fetchSelectedOption(newData);
                        }

                        setOpenDropdown(false);
                        setKeyword("");
                      }}
                    >
                      <div className="d-flex rounded p-1 bg-sea-green">
                        <img
                          src="/assets/search-Announcement.svg"
                          className="image-contain rounded-50"
                          style={{ height: "20px", width: "20px" }}
                        />
                      </div>
                      <div className="font-16">{option.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="d-flex d-flex-wrap gap-1">
        {selectedOption?.map((option, index) => (
          <div
            key={index}
            className="d-flex gap-1 bg-light-sea-green rounded d-align-center d-justify-center p-1 border"
          >
            <div className="bg-sea-green rounded d-flex d-justify-center d-align-center">
              <img
                height={20}
                width={20}
                src="/assets/search-Announcement.svg"
                alt="category-icon"
              />
            </div>
            <div className="font-16 text-sea-green">
              {option.name ? option.name : ""}
            </div>

            <img
              src="assets/cancel.svg"
              alt=""
              className="m-auto"
              onClick={() => {
                const updatedOptions = selectedOption.filter(
                  (existing) => existing !== option
                );

                setSelectedOption(updatedOptions);
                fetchSelectedOption(updatedOptions);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default SelectProductTypeModal;
