import ReactDOM from "react-dom";

const Tooltip = ({ children, style }) => {
  return ReactDOM.createPortal(
    <div style={style}>
      {children}
    </div>,
    document.body // Render it to the body
  );
};

export default Tooltip;
