import Cookies from "js-cookie";
import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "../styles/modular css/FollowingCompaniesList.module.css";
import { postMethod } from "../utils/apiMethod";
import { toastError, toastSuccess } from "../utils/Toasters";
import { StateContext } from "./Context/StateContext";
import ConfirmationModal from "./general/Modals/ConfirmationModal";
import Tooltip from "./Tooltip";

function FollowingCompaniesList({ companiesFollowed, fromModal }) {
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState("");
  const { setFollowStatusChanged } = useContext(StateContext);
  const location = useLocation();
  const [tooltipData, setTooltipData] = useState({
    visible: false,
    x: 0,
    y: 0,
    content: "",
    companyId: "",
  });

  const showTooltip = (e, content, companyId) => {
    const rect = e.target.getBoundingClientRect();
    setTooltipData({
      visible: true,
      x: rect.left + rect.width / 2 + window.scrollX, // Adjust for horizontal scroll
      y: rect.top - 10 + window.scrollY, // Adjust for vertical scroll
      content,
      companyId,
    });
  };

  const hideTooltip = () => {
    setTooltipData({ visible: false, x: 0, y: 0, content: "", companyId: "" });
  };

  const handleModalYes = () => {
    let fcmToken = Cookies.get("fcm");
    const raw = JSON.stringify({
      fcmToken: fcmToken || null,
    });

    postMethod(`users/followUnfollowCompany/${id}`, raw)
      .then((res) => {
        if (res?.status) {
          setShowModal(false);
          document.body.style.overflowY = "unset";
          toastSuccess(res.message);
          setFollowStatusChanged(true);
          setId("");
        }
      })
      .catch((error) => {
        toastError(error.message);
      });
  };

  const handleModalNo = () => {
    setShowModal(false);
    document.body.style.overflowY = "unset";
  };

  return (
    <>
      <div
        className={`${
          fromModal==true && styles.logoGrid
        } d-grid gap-2 d-align-center d-justify-center ${
          companiesFollowed?.length > 1
            ? `${fromModal ? "grid-col-3" : "grid-col-2"} `
            : "grid-col-1"
        }`}
      >
        {companiesFollowed.map((company, index) => (
          <Link
            state={{ from: location.pathname }}
            to={`/companyProfile/${company?.companyId}#Products`}
            key={index}
          >
            <div
              onMouseEnter={(e) =>
                showTooltip(e, company?.userFollows?.name, company?.companyId)
              }
              onMouseLeave={hideTooltip}
            >
              <img
                src={
                  company?.userFollows?.thumbnailUrl ||
                  "/assets/Image-Not-Found.png"
                }
                className="rounded-10"
                style={{ height: "100px", objectFit: "contain" }}
              />
            </div>
          </Link>
        ))}

        {tooltipData.visible && (
          <Tooltip
            style={{
              position: "absolute",
              top: tooltipData.y,
              left: tooltipData.x,
              transform: "translate(-50%, -100%)",
              zIndex: 1000,
              backgroundColor: "var(--yellow2)",
              padding: "10px",
              borderRadius: "8px",
            }}
          >
            <div className={`${styles.tooltiptext} d-flex d-flex-column gap-1`}>
              <span>{tooltipData?.content}</span>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setId(tooltipData.companyId); // Use the company ID from tooltip data
                  setShowModal(true);
                }}
              >
                Unfollow
              </button>
            </div>
          </Tooltip>
        )}
      </div>

      {showModal && (
        <div>
          <ConfirmationModal onYes={handleModalYes} onNo={handleModalNo} />
        </div>
      )}
    </>
  );
}

export default FollowingCompaniesList;