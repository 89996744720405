import { getToken } from "../auth/cookies";
import { toastError } from "./Toasters";
const url = process.env.REACT_APP_BASE_URL;
export function bearerToken() {
  const token = getToken();
  return token;
}

async function responseChecker(response) {
  if (response.ok) {
    const res = await response.json();
    if (Array.isArray(res.data)) {
      return { status: true, data: [...res.data] };
    } else if (typeof res.data === "object") {
      return { status: true, data: res.data };
    } else {
    }
  } else if (response.status == 401) {
    return { status: false, code: 401, message: "Session Expired." };
  } else if (response.status == 400) {
    const res = await response.json();
    return { status: false, code: 400, message: res };
  } else {
    //  Will do it later
    return {
      status: false,
      code: response.status,
      message: "Something went wrong.",
    };
  }
}

export const LoginQuery = async (token,raw) => {
  var myHeaders = new Headers();
  if(token&& token!=null && token!=undefined) myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };
  if (raw) {
    requestOptions = {
      ...requestOptions,
      body: JSON.stringify(raw)
    }
  }

  try {
    const response = await fetch(url + "auth/loginUser", requestOptions);
    return response;
  } catch (e) {
    toastError(e?.message);
    return e
  }
};
