import React, { useContext, useState } from "react";
import SignInWithGoogle from "./general/SignInWithGoogle";
import { toastError, toastSuccess, toastWarn } from "../utils/Toasters";
import { ToastContainer } from "react-toastify";
import useFirebaseAuth from "../auth/useFirebaseAuth";
import EmailVerificationModal from "./general/Modals/EmailVerificationModal";
import { useNavigate } from "react-router-dom";
import {
  removeOnBoardCookie,
  setOnBoardCookie,
  setUserCookie,
} from "../auth/cookies";
import Footer from "./general/Footer";
import { StateContext } from "./Context/StateContext";
import styles from "../styles/modular css/RegisterForm.module.css";
import SignInWithLinkedIn from "./SignInWithLinkedIn";
import RegisterSuccessModal from "./general/Modals/RegisterSuccessModal";
import "react-toastify/dist/ReactToastify.css";

function RegisterForm() {
  const url = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  const { setLoading } = useContext(StateContext);

  const {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
  } = useFirebaseAuth();
  const [user, setUser] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showEmailVerificationModal, setShowEmailVerificationModal] =
    useState(false);
  const [showRegisterSuccess, setShowRegisterSuccess] = useState(false);

  async function handleRegisterApiCall(e) {
    e.preventDefault();
    setLoading(true);
    if (password?.trim() == confirmPassword?.trim()) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        name: fullName,
        email: email,
        password: password?.trim(),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          url + "auth/registerUserPassword",
          requestOptions
        );
        const data = await response.json();
        if (data.status == true) {
          removeOnBoardCookie();
          setUserCookie(data?.user);
          toastSuccess(data?.message);

          //code to send verification email
          // userCredential.user.sendEmailVerification();
          if (data?.sendEmail == true) setShowEmailVerificationModal(true);
          else setShowRegisterSuccess(true);
        } else {
          toastError(data?.message);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        toastError(e?.message);
      }
    } else {
      setLoading(false)
      toastError("Passwords do not match.")
    }
  }

  return (
    <div className="d-flex d-flex-column">
      {/* Header */}
      <div className="d-flex d-justify-center col-12">
        <img src="/assets/FutrLogo.png" className="col-2" />
      </div>

      {/* Form */}
      <form
        id="registerForm"
        className={`${styles.registerForm} bg-white`}
        autoComplete="off"
        style={{ paddingInline: "5%", paddingBlock: "3%", paddingRight: "0px" }}
        onSubmit={handleRegisterApiCall}
      >
        <input type="text" style={{ display: "none" }} />
        <input type="password" style={{ display: "none" }} />

        <div className="col-12 col-md-6 col-xl-4 d-flex d-flex-column gap-4">
          <div className="d-flex d-flex-column gap-3 col-11 d-justify-center">
            <div className="aqua-heading m-auto">Join Now</div>

            {/* FULL NAME */}
            <div
              className="d-flex d-align-center form-input-field-div gap-2 p-3"
              style={{ height: "70px" }}
            >
              <img src="/assets/full-name-icon.svg" />
              <div className="h-full w-full">
                <input
                  className="border-none w-full h-full form-input-field"
                  placeholder="Enter your full name"
                  type={"text"}
                  value={fullName}
                  required={true}
                  onChange={(e) => {
                    const regex = /^[a-zA-Z\s]*$/;

                    if (regex.test(e.target.value)) {
                      setFullName(e.target.value);
                    }
                  }}
                />
              </div>
            </div>

            {/* EMAIL */}
            <div
              className="d-flex d-align-center form-input-field-div gap-2 p-3"
              style={{ height: "65px" }}
            >
              <img src="/assets/email-envelope-icon.svg" />
              <div className="h-full w-full">
                <input
                  className="border-none w-full h-full form-input-field"
                  placeholder="Enter your email"
                  type="email"
                  value={email}
                  required={true}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="new-email"
                  name="new-email"
                  id="new-email"
                />
              </div>
            </div>

            {/* PASSWORD */}
            <div
              className="d-flex d-align-center form-input-field-div gap-2 p-3"
              style={{ height: "65px" }}
            >
              <img src="/assets/password-lock-icon.svg" />
              <div className="h-full col-8 border-right-grey">
                <input
                  className="border-none w-full h-full form-input-field"
                  placeholder="New Password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  required={true}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="new-password"
                  name="new-password"
                  id="new-password"
                />
              </div>
              <div
                role="button"
                className="d-flex d-justify-center col-2 font-20 f-400 text-sea-green cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? "Hide" : "Show"}
              </div>
            </div>

            {/* CONFIRM PASSWORD */}
            <div
              className="d-flex d-align-center form-input-field-div gap-2 p-3"
              style={{ height: "65px" }}
            >
              <img src="/assets/password-lock-icon.svg" />
              <div className="h-full col-8 border-right-grey">
                <input
                  className="border-none w-full h-full form-input-field"
                  placeholder="Confirm Password"
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  required={true}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  autoComplete="new-password"
                  name="confirm-new-password"
                  id="confirm-new-password"
                />
              </div>
              <div
                className="d-flex d-justify-center col-2 font-20 f-400 text-sea-green cursor-pointer"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? "Hide" : "Show"}
              </div>
            </div>

            <button className="btn btn-sea-green font-20 l-32" type="submit">
              Sign Up
            </button>

            <SignInWithGoogle />

            <SignInWithLinkedIn text="Sign Up with LinkedIn" />
            <div className="font-20 text-center mt-10">
              Already have an account?
              <span
                className="text-sea-green font-20 f-600 cursor-pointer"
                onClick={() => navigate("/")}
              >
                {" "}
                Sign In
              </span>
            </div>
          </div>
        </div>

        {/* Image */}
        <div className="col-12 col-md-6 col-xl-8 d-flex d-justify-center d-align-center">
          <img
            src="/assets/ImageHeader1.png"
            alt="Image Header"
            className="col-12"
            style={{ objectFit: "contain" }}
          />
        </div>
      </form>

      <Footer btnLink="/register#registerForm" />

      {/* Email Verification Modal */}
      {showEmailVerificationModal && (
        <EmailVerificationModal
          onClickBackToLogin={() => navigate("/")}
          onClickClose={() => setShowEmailVerificationModal(false)}
        />
      )}
      {showRegisterSuccess && (
        <RegisterSuccessModal
          onClickBackToLogin={() => navigate("/")}
          onClickClose={() => setShowRegisterSuccess(false)}
        />
      )}
      <ToastContainer />
    </div>
  );
}

export default RegisterForm;
