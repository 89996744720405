import React, { useContext, useEffect } from "react";
import { getMessaging, onMessage } from "firebase/messaging";
import { initializeApp, getApps } from "firebase/app";
import useFcmToken from "../../auth/useFcmToken";
import { toastError, toastSuccess } from "../../utils/Toasters";
import { getMethod } from "../../utils/apiMethod";
import { StateContext } from "../Context/StateContext";

export default function Listener() {
  const { fcmToken, notificationPermissionStatus } = useFcmToken();
  const { setShowDot } = useContext(StateContext);

  // Use the token as needed
  const checkForNewNotification = async () => {
    try {
      let res = await getMethod(`notification/getUnReadNotification`);
      if (res?.data?.count > 0) setShowDot(true);
    } catch (error) {
      toastError(error.message);
    }
  };

  const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG)

  // Initialize Firebase
  const app = !getApps().length ? initializeApp(firebaseConfig) : getApps()[0];

  useEffect(() => {
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
        navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then((registration) => {
            // Get the Firebase Messaging instance
            const messaging = getMessaging(app);

            // Set up a message listener for FCM messages
            const unsubscribe = onMessage(messaging, (payload) => {
                checkForNewNotification();
                toastSuccess(payload?.notification?.body)
            });

            return () => {
                unsubscribe(); // Unsubscribe from the onMessage event
            };
        })
        .catch((error) => {
            console.error('Service Worker registration failed:', error);
        });
    }
}, []);

  useEffect(() => {
    checkForNewNotification();
  }, []);

  return (
    <div>
    </div>
  );
}
