import { useState } from "react";
import ScoreSlider from "../ScoreSlider";
import { postFormMethod, postMethod } from "../../../utils/apiMethod";
import { toastError, toastSuccess } from "../../../utils/Toasters";

function ScoreModal({handleCancel, handleRateCompany, companyId, setRefresh}) {

    const modalStyle =  {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: "1000",
     }

     const [sliders, setSliders] = useState([{ id: "Executives", value: 0 }, { id: "Product Quality", value: 0 },
      { id: "Market Fit", value: 0}, { id: "Ability to Execute", value: 0} ]);

     // Callback function to update the slider value in the state
  const handleSliderChange = (id, value) => {
    // Update the state based on the slider id
    setSliders((prevSliders) =>
      prevSliders.map((slider) => (slider.id === id ? { ...slider, value } : slider))
    );
  };

  const handleSubmit = (e) => {

    submitHandler(e);

    handleCancel();

  }

  const submitHandler = async(e) => {
    // setLoading(true);

      // checking of the values changed in field

    var raw = JSON.stringify({
        // "companyId": companyId,
        "companyId": companyId,
        "executivesRating": sliders[0].value,
        "productQualityRating": sliders[1].value,
        "marketFitRating": sliders[2].value,
        "abilityToExecuteRating": sliders[3].value
    })

      postMethod(`users/rateCompany`, raw)
        .then((res) => {
          if (res.status == true) {
            toastSuccess(res.message);
            if(setRefresh){
         
              setRefresh(prev => !prev)
            }
          }else{
            toastError(res.message)
          }
         
        })
        .catch((error) => {
          // setLoading(false);
          toastError(error.message);
        });


    
  };
    
  return (
    <div className="modal-wrapper">

    <div className='col-7 d-flex bg-white col-4 rounded-20 box-shadow1 m-auto' style={modalStyle}>
       <div className="col-12 d-flex d-flex-column p-5 gap-3">

        <div className="font-32 f-700">Score this company</div>

        <div className="col-12 d-flex d-flex-column pr-8 pl-8 pt-2 pb-5 rounded-20 border-grey">

            {/* EXECUTIVES */}
            {
                sliders.map((slider,idx)=>{
                    return(

                        <div key={idx} className={`d-flex d-flex-column pt-5 pb-5 ${idx !== sliders.length-1?"border-bottom-dark":""}`}>

                            <div className="font-26 f-500">
                                {slider.id}<span className="text-red font-26"> *</span>
                            </div>
                            <div className="d-flex">
                                <div className="col-3">
                                    <div className={`bg-grey-18 rounded-10 col-10 font-26 d-flex d-align-center d-justify-center ${slider.value > 0?"text-black":"text-grey-2"}`} style={{height:"73px"}}>

                                        {slider.value}

                                    </div>
                                </div>
                                <div className="col-9">
                                    <ScoreSlider key={slider.id} id={slider.id} onSliderChange={handleSliderChange} initialValue={slider.value}/>
                                </div>
                            </div>

                        </div>
                    )
                })
            }
        </div>

        <div className="col-12 d-flex d-flex-row-reverse gap-2">

                <button className="btn-modal bg-sea-green text-white border-none font-26"
                onClick={handleSubmit}>Rate this Company</button>
                <button className="btn-modal font-26 border-sea-green text-sea-green bg-white"
                onClick={handleRateCompany}>Cancel</button>
        
            <div>

            </div>

        </div>
        
       </div>
    </div>
    </div>
  );
}

export default ScoreModal;

