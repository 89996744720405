import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const AnalyticsTracker = () => {
  const location = useLocation();
  const totalTimeRef = useRef(0);
  const startTimeRef = useRef(0);
  const previousPathRef = useRef("");
  const hasUserInteractedRef = useRef(false);
  const maxScrollDepthRef = useRef(0);
  const previousRouteRef = useRef("");
  const isAnalyticsSentRef = useRef(false);
  const isScrollTrackingEnabledRef = useRef(false);

  useEffect(() => {
    const startTimer = () => {
      if (startTimeRef.current === 0) {
        startTimeRef.current = new Date().getTime();
      }
    };

    const pauseTimer = () => {
      if (startTimeRef.current) {
        const endTime = new Date().getTime();
        totalTimeRef.current += endTime - startTimeRef.current;
        startTimeRef.current = 0;
      }
    };

    const sendPageEngagement = () => {
      if (!isAnalyticsSentRef.current) {
        const timeSpentInSeconds = Math.round(totalTimeRef.current / 1000);
        const pageHeight = document.body.scrollHeight - window.innerHeight;
        const scrollDepthPercentage =
          isScrollTrackingEnabledRef.current && maxScrollDepthRef.current > 0
            ? Math.min((maxScrollDepthRef.current / pageHeight) * 100, 100)
            : 0;

        ReactGA.event("PageEngagement", {
          page_route: previousPathRef.current,
          time_spent_sec: timeSpentInSeconds,
          value: timeSpentInSeconds,
        });

        console.log(
          `📊 Time spent: ${timeSpentInSeconds}s, Scroll depth: ${scrollDepthPercentage}%`
        );
        isAnalyticsSentRef.current = true;
      }
    };

    const sendBounceRate = () => {
      const timeSpentInSeconds = Math.round(totalTimeRef.current / 1000);
      const pageHeight = document.body.scrollHeight - window.innerHeight;
      const scrollDepthPercentage =
        isScrollTrackingEnabledRef.current && maxScrollDepthRef.current > 0
          ? Math.min((maxScrollDepthRef.current / pageHeight) * 100, 100)
          : 0;
      const isBounce =
        !hasUserInteractedRef.current &&
        scrollDepthPercentage <= 50 &&
        timeSpentInSeconds <= 10;

      ReactGA.event("BounceRate", {
        page_route: previousRouteRef.current,
        value: isBounce ? 0 : 1,
      });

      console.log(
        `📉 BounceRate Event: Route: ${previousRouteRef.current}, Bounce: ${isBounce}`
      );
    };

    const handleVisibilityChange = () => {
      document.visibilityState === "hidden" ? pauseTimer() : startTimer();
    };

    const handleBeforeUnload = () => {
      pauseTimer();
      sendPageEngagement();
    };

    const handleUserInteraction = (event) => {
      if (event.target.classList.contains("backButton")) {
        return;
      }
      hasUserInteractedRef.current = true;
    };

    const handleScroll = () => {
      if (!isScrollTrackingEnabledRef.current) return;
      if (window.scrollY > 0) {
        maxScrollDepthRef.current = Math.max(
          maxScrollDepthRef.current,
          window.scrollY
        );
      }
    };

    const currentPath = location.pathname + location.hash;
    const currentRoute = location.pathname;

    if (currentRoute !== previousRouteRef.current) {
      if (previousRouteRef.current) {
        pauseTimer();
        sendBounceRate();
      }
      previousRouteRef.current = currentRoute;
    }

    if (currentPath !== previousPathRef.current) {
      if (previousPathRef.current) {
        pauseTimer();
        sendPageEngagement();
      }

      previousPathRef.current = currentPath;
      totalTimeRef.current = 0;
      startTimeRef.current = 0;
      isAnalyticsSentRef.current = false;
      // Reset user interaction *after* navigating to a new page
      setTimeout(() => {
        hasUserInteractedRef.current = false;
      }, 500);
      maxScrollDepthRef.current = 0;
      isScrollTrackingEnabledRef.current = false;

      ReactGA.send({ hitType: "pageview", page_path: currentPath });

      setTimeout(() => {
        isScrollTrackingEnabledRef.current = true;
        startTimer();
      }, 1000);
    }

    window.addEventListener("click", handleUserInteraction);
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      pauseTimer();
      sendPageEngagement();
      window.removeEventListener("click", handleUserInteraction);
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [location]);

  return null;
};

export default AnalyticsTracker;