import React, { useEffect, useState } from 'react'

//import components
import Tiles from "../general/cards/publicProfile/Tiles";
import { getMethod } from '../../utils/apiMethod';
import { toastError } from '../../utils/Toasters';
import { useContext } from 'react';
import { StateContext } from '../Context/StateContext';
import styles from "../../styles/modular css/Metrics.module.css";

function Metrics({companyId}) {
  const { setLoading } = useContext(StateContext);
    const [metricsDetails, setMetricsDetails] = useState(
        {
          totalEmployees:0,
          totalCustomers:0,
          clientRetentionPercent:0,
          npcScore:0,
        }
      )
    useEffect(() => {
        setLoading(true);
        getMethod(`users/viewCompanyMetrics/${companyId}`)
          .then((res) => {
            if (res?.status == true) {
                    const updatedMetricsDetails = { ...metricsDetails,
                        totalEmployees: res.data?.totalEmployees,
                        totalCustomers: res.data?.totalCustomers,
                        clientRetentionPercent: res.data?.clientRetentionPercent,
                        npcScore: res.data?.npcScore,
                    };
                    setMetricsDetails(updatedMetricsDetails)
            }
            setLoading(false)
          })
          .catch((error) => {
            setLoading(false);
            toastError(error.message);
          });
      }, []);
  return (
    <div className="d-flex d-flex-column bg-light-blue rounded-16 p-6 gap-2">
      {/* Heading text */}
      <div>
        <h1 className="text-blue">Metrics</h1>
      </div>

      {/* Tiles */}
      <div className={`${styles.metricsGrid}`}>
        <div className="">
          <Tiles
            image={"/assets/Employees.svg"}
            heading={"Employees"}
            type={"metrics"}
            bg={"rgba(228, 251, 249, 1)"}
            content={
              metricsDetails?.totalEmployees
                ? metricsDetails?.totalEmployees
                : "N/A"
            }
          />
        </div>
        <div className="">
          <Tiles
            image={"/assets/Customers.svg"}
            heading={"Customers"}
            type={"metrics"}
            bg={"rgba(234, 251, 228, 1)"}
            content={
              metricsDetails?.totalCustomers
                ? metricsDetails?.totalCustomers
                : "N/A"
            }
          />
        </div>
        {/* <Tiles image={"/assets/TotalFunding.svg"} heading={"NPC Score"} type={"metrics"} bg={"rgba(228, 237, 251, 1)"} content={metricsDetails.npcScore}/> */}
          <div className="">
            <Tiles
              image={"/assets/ClientRetention.svg"}
              heading={"Client retention rate"}
              type={"metrics"}
              bg={"rgba(244, 228, 251, 1)"}
              content={`${metricsDetails?.clientRetentionPercent?`${metricsDetails?.clientRetentionPercent}%`:"N/A"}`}
            />
          </div>
      </div>
    </div>
  );
}

export default Metrics
