import React, { useContext } from "react";
import useFirebaseAuth from "../../auth/useFirebaseAuth";
import { LoginQuery } from "../../utils/auth";
import {
  removeOnBoardCookie,
  setUserCookie,
  setOnBoardCookie,
  setToken,
} from "../../auth/cookies";
import { useLocation, useNavigate } from "react-router-dom";
import { toastError } from "../../utils/Toasters";
import { StateContext } from "../Context/StateContext";

function SignInWithGoogle() {
  const url = process.env.REACT_APP_BASE_URL;

  const navigate = useNavigate();
  const { signInWithGoogle, setRole } = useFirebaseAuth();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const { loading, setFirstLoading } = useContext(StateContext);
  const query = useQuery();
  let linkTo = query?.get("navigateTo");

  function handleRegisterApiCall(userCredential, details) {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + userCredential.user.multiFactor.user.accessToken
    );

    var raw = JSON.stringify({
      name: details.name,
      ownerName: details.ownerName,
      phone: details.phone,

      //   email: credentials.email ,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url + "auth/registerUser", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          removeOnBoardCookie();
          setOnBoardCookie(userCredential.user.multiFactor.user.accessToken);
          setUserCookie(result);
          navigate("/dashboard");
        } else {
        }
      })
      .catch((error) => {
      });
  }

  const signInWithGoogleHandler = async () => {
    setFirstLoading(true);
    signInWithGoogle()
      .then(async(authUser) => {
        LoginQuery(authUser.user.multiFactor.user.accessToken)
          .then((res) => {
            return res.json();
          })
          .then((result) => {
            if (result?.status) {
              setToken(authUser.user.multiFactor.user.accessToken)
              setUserCookie(result);
              setFirstLoading(false);
              if (linkTo && linkTo != null && linkTo != undefined) navigate(linkTo);
              else navigate("/dashboard");
            } else {
              setFirstLoading(false);
              toastError(result.message);
            }

            setFirstLoading(false)
          });
      })
      .catch((error) => {
        // setErrorBox(true)
        // setErrorMsg(error.message);
        setFirstLoading(false);
        if (error.message == "Firebase: Error (auth/user-not-found).") {
          toastError("User Not Found", {
            toastId: "1",
          });
        } else if (error.message == "Firebase: Error (auth/wrong-password).") {
          toastError("Password Invalid", {
            toastId: "1",
          });
        } else if (
          error.message ==
          "Firebase: There is no user record corresponding to this identifier. The user may have been deleted. (auth/user-not-found)."
        ) {
          toastError("Invalid Email", {
            toastId: "1",
          });
        } else if (
          error.message ==
          "Firebase: The password is invalid or the user does not have a password. (auth/wrong-password)."
        ) {
          toastError("Incorrect Password", {
            toastId: "1",
          });
        } else {
          var code = error.code.substring(error.code.lastIndexOf("/") + 1);
          var newcode = code.replaceAll("-", " ");
          toastError(newcode, {
            toastId: "2",
          });
        }
      });
  };

  return (
    <div
      style={{borderRadius:"30px"}}
      className="input-field p-2 font-20 text-light-grey2 rounded-32 text-center d-flex d-justify-center gap-2 hover-effect"
      onClick={signInWithGoogleHandler}
    >
      <img src="/assets/GoogleLogo.svg" />
      Sign in with Google
    </div>
  );
}

export default SignInWithGoogle;