import React, { useEffect, useState } from "react";

import References from "../general/cards/publicProfile/References";
import { getMethod } from "../../utils/apiMethod";
import { toastError } from "../../utils/Toasters";
import { useContext } from "react";
import { StateContext } from "../Context/StateContext";
import CustomerReviews from "../general/cards/publicProfile/CustomerReviews";
import styles from "../../styles/modular css/ReferencesCategory.module.css";
import Pagination from "../general/Pagination";

function ReferencesCategory({ companyId }) {
  const { setLoading } = useContext(StateContext);

  const [references, setReferences] = useState([]);
  //pagination states
  const [totalResults, setTotalResults] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(4);

  const fetchActivePage = (page) => {
    setActivePage(page);
  };

  const fetchLimit = (newLimit) => {
    setLimit(newLimit);
  };

  useEffect(() => {
    setLoading(true);

    getMethod(
      `users/getCompanyReferences/${companyId}?${
        limit ? `limit=${limit}` : ""
      }&${activePage ? `page=${activePage}` : ""}`
    )
      .then((res) => {
        setLoading(false);
        if (res?.status) {
          setReferences([...res.data?.results]);
          setActivePage(res.data?.page);
          setTotalResults(res.data?.totalResults);
          setTotalPages(res.data?.totalPages);
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
      });
  }, []);

  return (
    <div className="d-flex d-flex-column bg-light-blue rounded-16 p-6 gap-2">
      {/* Heading text */}
      <div>
        <h1 className="text-blue font-20">References</h1>
      </div>

      {/* Tiles */}
      <div className={`${styles.referenceGrid} d-grid grid-col-4 gap-3`}>
        {references.map((reference, index) => {
          return (
            <CustomerReviews
              name={reference.userName}
              image={reference.referralUserPicUrl}
              companyName={reference.companyName}
              description={reference?.description}
              companyPic={reference.companyImageKeyUrl}
              linkedinUrl={reference.linkedinUrl}
              title={reference.role}
            />
          );
        })}
      </div>

      <Pagination
        fetchActivePage={fetchActivePage}
        fetchLimit={fetchLimit}
        totalPages={totalPages}
        records={totalResults}
      />
    </div>
  );
}

export default ReferencesCategory;
