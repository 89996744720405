import React from 'react'

function DescriptionModal({ closeHandler, description }) {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1000",
    height: "auto",
  };
  return (
    <div className="modal-wrapper">
      <div
        className="d-flex d-flex-column gap-4 bg-white col-11 col-sm-9 col-md-7 col-lg-6 col-xl-5 col-xxl-4 rounded-20 box-shadow1 m-auto p-6"
        style={modalStyle}
      >
        <div className="d-flex d-align-center d-justify-space-between">
          <h3>Description</h3>
          <svg
            onClick={() => closeHandler()}
            className="cursor-pointer"
            width="26"
            height="26"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.25 13.59L10.25 10.59L13.25 13.59L13.84 13L10.84 10L13.84 7L13.25 6.41L10.25 9.41L7.25 6.41L6.66 7L9.66 10L6.66 13L7.25 13.59ZM10.2525 17.5C9.21583 17.5 8.24083 17.3033 7.3275 16.91C6.41472 16.5161 5.62056 15.9817 4.945 15.3067C4.26944 14.6322 3.73472 13.8389 3.34083 12.9267C2.94694 12.0144 2.75 11.0397 2.75 10.0025C2.75 8.96583 2.94667 7.99083 3.34 7.0775C3.73389 6.16472 4.26833 5.37056 4.94333 4.695C5.61778 4.01944 6.41111 3.48472 7.32333 3.09083C8.23556 2.69694 9.21028 2.5 10.2475 2.5C11.2842 2.5 12.2592 2.69667 13.1725 3.09C14.0853 3.48389 14.8794 4.01833 15.555 4.69333C16.2306 5.36778 16.7653 6.16111 17.1592 7.07333C17.5531 7.98556 17.75 8.96028 17.75 9.9975C17.75 11.0342 17.5533 12.0092 17.16 12.9225C16.7661 13.8353 16.2317 14.6294 15.5567 15.305C14.8822 15.9806 14.0889 16.5153 13.1767 16.9092C12.2644 17.3031 11.2897 17.5 10.2525 17.5Z"
              fill="black"
            />
          </svg>
        </div>
        <p className='m-0 h4 f-400 l-22 text-black'>{description}</p>
      </div>
    </div>
  );
}

export default DescriptionModal