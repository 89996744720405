import { useState, useEffect } from "react";
import firebase from "./firebaseConfig";
import {
  setToken,
  getToken,
  removeToken,
  removeUser,
  removeUserId,
} from "./cookies";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastError, toastSuccess } from "../utils/Toasters";
import {
  getAuth,
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail,
} from "firebase/auth";
import { postMethod, unSecurePostMethod } from "../utils/apiMethod";

export default function useFirebaseAuth() {
  const auth = firebase.auth();
  const googleProvider = new firebase.auth.GoogleAuthProvider();
  googleProvider.addScope("email"); // Already defined in your OAuth consent
  googleProvider.addScope("profile"); // Already defined in your OAuth consent
  googleProvider.addScope("openid"); // Standard OpenID Connect scope

  const formatAuthUser = (user) => ({
    uid: user.uid,
    email: user.email,
    name: user.displayName,
    token: user.multiFactor.user.accessToken,
    phone: user.phoneNumber,
    photo: user.photoURL,
    verified: user.emailVerified,
  });

  const authStateChanged = async (authState) => {
    if (!authState) {
    }
  };

  const clear = () => {
    removeToken();
    removeUserId();
    removeUser();
  };

  const createUserWithEmailAndPassword = (email, password) =>
    auth.createUserWithEmailAndPassword(email, password);

  const signInWithEmailAndPassword = (email, password) =>
    auth.signInWithEmailAndPassword(email, password);

  const signInWithCustomToken = (firebaseToken) =>
    firebase.auth().signInWithCustomToken(firebaseToken);

  const signOut = () => auth.signOut().then(clear);

  const forgotPassword = async (email) => {
    try {
      var raw = {
        email: email,
        type: "user",
        env: process.env.REACT_APP_ENV,
      };
      let response = await unSecurePostMethod(`auth/forgetPassword`, raw);
      if (response?.status == true) {
        return response
      }else{
        toastError(response?.message)
      }
    } catch (error) {
      toastError(
        `Error fetching sign-in methods or sending reset email:
          ${error.message}`
      );
    }
  };

  const changePassword = async (newPassword, oldPassword, myFunc) => {
    try {
      var raw = JSON.stringify({
        oldPassword: oldPassword,
        newPassword: newPassword,
      });
      let response = await postMethod(`users/changePassword`, raw);
      if (response?.status == true) {
        toastSuccess("Password changed successfully!");
        myFunc();
      }
    } catch (error) {
      toastError(error?.message);
    }
  };

  const signInWithGoogle = () =>
    firebase.auth().signInWithPopup(googleProvider);

  const verifier = () => {
    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // Handle response if needed
        },
        "error-callback": (error) => {
          toastError(error.message);
        },
        "expired-callback": () => {
          toastError("Recaptcha expired.");
        },
      }
    );
    return recaptchaVerifier;
  };

  const signInWithPhoneNumber = (phone, appVerifier) =>
    firebase.auth().signInWithPhoneNumber(phone, appVerifier);

  const setRole = (firebaseUid) => {
    firebase.auth().setCustomUserClaims(firebaseUid, { role: "user" });
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(authStateChanged);
    return () => {
      unsubscribe();
    };
  }, []);

  // returns function references for using the firebase authentication methods
  return {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    forgotPassword,
    changePassword,
    signInWithGoogle,
    formatAuthUser,
    signInWithPhoneNumber,
    verifier,
    setRole,
    signInWithCustomToken,
  };
}
