import React, { useEffect, useState } from 'react'
import Awards from '../general/cards/publicProfile/Awards'
import { getMethod } from '../../utils/apiMethod';
import { toastError } from '../../utils/Toasters';
import { useContext } from 'react';
import { StateContext } from '../Context/StateContext';
import styles from "../../styles/modular css/AwardCategory.module.css"

function AwardsCategory({companyId}) {

  const { setLoading } = useContext(StateContext);


   const [awards, setAwards] = useState([])

   useEffect(() => {
      setLoading(true);
  
      getMethod(`users/getCompanyAwards/${companyId}`)
        .then((res) => {
          setLoading(false);
          if (res?.status) {
  
              setAwards([...res.data?.results])
            
          }
        })
        .catch((error) => {
          setLoading(false);
          toastError(error.message);
        });
    }, []);

  return (
    <div className="d-flex d-flex-column bg-light-blue rounded-16 p-6 gap-2">
    {/* Heading text */}
    <div>
      <h1 className="text-blue font-20">Awards</h1>
    </div>

   {/* Tiles */}
   <div className={`${styles.awardGrid} d-grid grid-col-4`}>

      {
         awards.map((award, index)=>{

            return (
              <div key={index} className={`mt-5`}>
                <Awards
                  brandImage={award.awardImageUrl}
                  brandName={award.awardName}
                  achievement={award?.achievement}
                />
              </div>
            );


           

         })
      }
         

             
      
         
          </div>
  </div>
  )
}

export default AwardsCategory
