import React, { useEffect, useState } from "react";

//import components
import News from "../general/cards/publicProfile/News";
import { getMethod } from "../../utils/apiMethod";
import { toastError } from "../../utils/Toasters";
import { useContext } from "react";
import { StateContext } from "../Context/StateContext";
import Pagination from "../general/Pagination";

function NewsCategory({ companyId }) {
  const { setLoading } = useContext(StateContext);
  const [newsData, setNewsData] = useState([]);
  //pagination states
  const [totalResults, setTotalResults] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(4);

  const fetchActivePage = (page) => {
    setActivePage(page);
  };

  const fetchLimit = (newLimit) => {
    setLimit(newLimit);
  };

  useEffect(() => {
    setLoading(true);
    getMethod(
      `users/getCompanyNews/${companyId}?${limit ? `limit=${limit}` : ""}&${
        activePage ? `page=${activePage}` : ""
      }`
    )
      .then((res) => {
        if (res?.status) {
          setNewsData([...res.data?.results]);
          setActivePage(res.data?.page);
          setTotalResults(res.data?.totalResults);
          setTotalPages(res.data?.totalPages);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
      });
  }, [limit, activePage, totalPages]);
  return (
    <div className="d-flex d-flex-column bg-light-blue rounded-16 p-6 gap-2">
      {/* Heading text */}
      <div>
        <h1 className="text-blue font-20">News</h1>
      </div>

      {/* Tiles */}
      <div className="d-flex d-flex-column gap-3 pb-5 mb-5">
        {newsData.map((item, index) => {
          return (
            <div key={index}>
              <News
                thumbnail={item?.image}
                heading={item?.title}
                description={item?.description}
                publishDate={item?.publishingDate}
                url={item?.url}
              />
            </div>
          );
        })}
        <Pagination
          fetchActivePage={fetchActivePage}
          fetchLimit={fetchLimit}
          totalPages={totalPages}
          records={totalResults}
        />
      </div>
    </div>
  );
}

export default NewsCategory;
