import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/general/ScrollToTop";

import "./styles/gap.css";
import "./styles/globals.css";
import "./styles/structure.css";
import "./styles/typography.css";
import "./styles/Futr.css";

import HomePage from "./components/HomePage";
import Dashboard from "./components/Dashboard";
import CompanyPublicProfile from "./components/CompanyPublicProfile";
import UserSettings from "./components/UserSettings";
import { useContext, useEffect } from "react";
import RegisterForm from "./components/RegisterForm";
import TestRoute from "./components/TestRoute";
import { StateContext } from "./components/Context/StateContext";
import GlobalLoader from "./components/general/GlobalLoader";
import SearchCompany from "./components/SearchCompany";
import FirstLoader from "./components/general/FirstLoader";
import ProtectedRoutes from "./ProtectedRoutes";
import ClaimProfile from "./components/claimProfile/ClaimProfile";
import UserClaim from "./components/claimProfile/UserClaim";
import ResetPassword from "./components/claimProfile/ResetPassword";
import PasswordResetSuccess from "./components/claimProfile/PasswordResetSuccess";
import LinkedInAuth from "./components/LinkedInAuth";
import Notification from "./components/Notification";
import NotFound from "./components/NotFound";
import ReactGA from "react-ga4";
import AnalyticsTracker from "./components/analytics/AnalyticsTracker";
import ChatBot from "./components/general/ChatBot";
import UserResetPassword from "./components/ResetPassword";
import { ToastContainer } from "react-toastify";

function App() {
  const { loading, firstLoading } = useContext(StateContext);

  useEffect(() => {
    // Initialize GA4 with your Measurement ID
    ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID, {
      gaOptions: {
        sessionControl: "start" // Forces a new session on refresh
      }
    });
  }, []);

  return (
    <div
      className="d-flex d-flex-column bg-white"
      style={{ minHeight: "100vh" }}
    >
      {firstLoading && <FirstLoader />}
      {loading && <GlobalLoader />}

      <BrowserRouter>
        <ScrollToTop /> {/* Add ScrollToTop component here */}
        <AnalyticsTracker />
        <ChatBot/>
        <Routes>
          <Route path="/register" element={<RegisterForm />} />
          <Route path="/linkedInAuth" element={<LinkedInAuth />} />
          <Route path="/testRoute" element={<TestRoute />} />
          {/* claim profile routes */}
          <Route path="/claim" element={<ClaimProfile />} />
          <Route path="/user-claim" element={<UserClaim />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/reset-success" element={<PasswordResetSuccess />} />

          <Route path="/" element={<HomePage />} />
          <Route path="/resetPassword" element={<UserResetPassword />} />
          <Route
            path="/dashboard"
            element={<ProtectedRoutes component={<Dashboard />} />}
          />
          <Route
            path="/companyprofile/:companyId"
            element={<ProtectedRoutes component={<CompanyPublicProfile />} />}
          />
          <Route
            path="/notifications"
            element={<ProtectedRoutes component={<Notification />} />}
          />
          <Route
            path="/settings"
            element={<ProtectedRoutes component={<UserSettings />} />}
          />
          <Route
            path="/search"
            element={<ProtectedRoutes component={<SearchCompany />} />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
