import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { DotLoader, PulseLoader } from "react-spinners";
import { getToken } from "../../auth/cookies";
import { getMethod, postMethod } from "../../utils/apiMethod";
import { toastError, toastSuccess } from "../../utils/Toasters";
import ToolTip from "./HoverToolTip";
import MarkdownRenderer from "./MarkdownRenderer";

// outside click check
function useOutsideAlerter(ref, handler) {
  useEffect(() => {
    function handleClickOutside(event) {
      // if click is outside call handler
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // clear event listner
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, handler]);
}

function ChatBot() {
  const [showChat, setShowChat] = useState(false);
  const [messsages, setMessages] = useState([]);
  const [btnLoad, setBtnLoad] = useState(false);
  const [text, setText] = useState("");
  const wrapperRef = useRef(null);
  var token = getToken();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [image, setImage] = useState(null);

  // Inside the ChatBot component
  useEffect(() => {
    const container = document.getElementById("messageContent");
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }, [messsages, showChat]);

  const showHandler = () => setShowChat((prev) => !prev);
  useOutsideAlerter(wrapperRef, showHandler);

  const getMessages = async () => {
    try {
      let response = await getMethod(
        `auth/listBotConversation?timezone=${timezone}`
      );
      if (response?.status == true) {
        setMessages(response?.data?.history);
      }
    } catch (error) {
      toastError(error?.message);
    }
  };

  const getUserDetails = () => {
    getMethod(`users/getMyUserDetails`)
      .then((res) => {
        if (res?.status) {
          setImage(res?.user?.profilePicUrl);
        }
      })
      .catch((error) => {
        toastError(error?.message);
      });
  };

  useEffect(() => {
    getMessages();
    getUserDetails();
  }, []);

  const submitQuery = async (e) => {
    e.preventDefault();
    setBtnLoad(true);
    let container = document.getElementById("messageContent");
    if (container) {
      container.scrollTop = container.scrollHeight;
    }

    let question = document.querySelector("input#chatbotInput")?.value;
    try {
      setMessages([
        ...messsages,
        {
          question: question,
          status: 0,
        },
      ]);

      let element = document.querySelector("input#chatbotInput");
      element.value = "";
      setText("");

      var raw = JSON.stringify({
        question: question,
        timezone: timezone,
      });
      let response = await postMethod(`auth/ask`, raw);
      if (response?.status == true) {
        setMessages(response?.data?.history);
      } else {
        setMessages([
          ...messsages,
          {
            question: question,
            status: -1,
          },
        ]);
      }
    } catch (error) {
      setMessages([
        ...messsages,
        {
          question: question,
          status: -1,
        },
      ]);
      toastError(error?.message);
    } finally {
      setBtnLoad(false);
    }
  };

  const clearConversationHandler = async () => {
    try {
      setBtnLoad(true);
      let response = await getMethod("auth/clearConversation");
      if (response?.status == true) {
        toastSuccess("Conversation cleared!");
        setMessages([]);
      }
    } catch (error) {
      toastError(error?.message);
    } finally {
      setBtnLoad(false);
    }
  };
  return (
    <>
      {token && token != null && token != undefined ? (
        <div
          className="p-fixed d-flex d-flex-column d-align-end d-justify-end pr-5 pb-5"
          style={{
            width: "fit-content",
            height: "fit-content",
            zIndex: "9999",
            right: "0",
            bottom: "0",
          }}
        >
          {showChat && (
            <div
              ref={wrapperRef}
              style={{ width: "318px", marginBottom: "68px" }}
              className="p-absolute d-flex d-flex-column box-shadow bg-white rounded-32"
            >
              <div className="d-flex d-flex-column pl-5 pr-5 pt-3 pb-3">
                <div className="d-flex d-justify-end d-align-center gap-1">
                <ToolTip
                  text={"Clear Conversation"}
                >
                  <svg
                    onClick={clearConversationHandler}
                    className="cursor-pointer"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 21C6.45 21 5.97917 20.8042 5.5875 20.4125C5.19583 20.0208 5 19.55 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.8042 20.0208 18.4125 20.4125C18.0208 20.8042 17.55 21 17 21H7ZM17 6H7V19H17V6ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z"
                      fill="#FF3535"
                    />
                  </svg>
                </ToolTip>
                  
                  <svg
                    className="cursor-pointer"
                    onClick={() => setShowChat(false)}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 6L6 18M6 6L18 18"
                      stroke="#090A0A"
                      stroke-width="1.125"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>

                <div
                  id="messageContent"
                  style={{ height: "300px", overflowY: "auto" }}
                  className="d-flex d-flex-column gap-2"
                >
                  <>
                    {messsages?.length > 0 ? (
                      <>
                        {messsages?.map((item, index) => (
                          <>
                            <span className="h6 f-400 l-18 text-center text-grey-3">
                              {item?.timestamp}
                            </span>
                            {/* question by user */}
                            <div className="d-flex d-flex-row-reverse gap-1">
                              {image ? (
                                <img
                                  src={image}
                                  alt="user-profile"
                                  width={28}
                                  height={28}
                                  className="rounded-50 object-contain"
                                />
                              ) : (
                                <img
                                  src="/assets/Image-Not-Found.png"
                                  alt="user-profile"
                                  width={28}
                                  height={28}
                                  className="rounded-50 object-contain"
                                />
                              )}
                              <div className="d-flex d-flex-column d-align-end">
                                <p className="m-0 pl-4 pr-4 pt-2 pb-2 h5 f-400 l-20 text-white bg-sea-green rounded-20 word-break">
                                  {item?.question}
                                </p>
                                <>
                                  {/* {item?.status == 0 && (
                                    <span className="font-10 text-right text-grey-17">
                                      sending...
                                    </span>
                                  )} */}
                                  {item?.status == -1 && (
                                    <span className="font-10 text-right text-red">
                                      unsent
                                    </span>
                                  )}
                                </>
                              </div>
                            </div>
                            {/* answer by chatbot */}
                            <div className="d-flex gap-1">
                              <img
                                src="/assets/FutrLogo.png"
                                alt="AI-profile"
                                width={36}
                                height={36}
                                className="rounded-50 object-contain"
                              />
                              <p className="d-flex d-align-center d-justify-center m-0 pl-4 pr-4 h5 f-400 l-20 text-black bg-grey-13 rounded-20 word-break">
                                <>
                                  {item?.status == 0 && (
                                    <PulseLoader
                                      size={8}
                                      color="#1CBAAB"
                                    ></PulseLoader>
                                  )}
                                  {item?.answer && (
                                    <MarkdownRenderer markdown={item?.answer} />
                                  )}
                                </>
                              </p>
                            </div>
                          </>
                        ))}
                      </>
                    ) : (
                      <span className="d-flex d-align-center d-justify-center text-center">
                        We are here to help!
                      </span>
                    )}
                  </>
                </div>
              </div>
              <form
                onSubmit={submitQuery}
                className="d-flex d-justify-space-between gap-1 border-top pl-5 pr-5 pt-3 pb-3"
              >
                <input
                  placeholder="Type your message"
                  className="col-12 pl-4 pt-3 pb-3 border rounded-40 pr-4"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  id="chatbotInput"
                  required
                />
                {!btnLoad && text && (
                  <button
                    type="submit"
                    style={{ width: "42px", height: "42px" }}
                    className="d-flex d-align-center d-justify-center bg-sea-green rounded-50 border-none cursor-pointer"
                  >
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.8014 13.695L20.6025 3.89754M11.08 14.1356L13.3024 18.5805C13.8395 19.6546 14.108 20.1917 14.4463 20.3357C14.7399 20.4607 15.0755 20.4381 15.3496 20.2748C15.6656 20.0867 15.8596 19.5185 16.2477 18.382L20.4468 6.08447C20.7849 5.09421 20.954 4.59908 20.8383 4.27154C20.7376 3.9866 20.5135 3.76246 20.2285 3.66179C19.901 3.54607 19.4059 3.71514 18.4156 4.05328L6.11812 8.25242C4.98163 8.64049 4.41338 8.83453 4.22527 9.15044C4.06202 9.42459 4.0394 9.76019 4.16438 10.0538C4.3084 10.3921 4.84547 10.6606 5.91961 11.1977L10.3645 13.4201C10.5414 13.5086 10.6299 13.5528 10.7066 13.6119C10.7747 13.6644 10.8357 13.7254 10.8881 13.7935C10.9473 13.8701 10.9915 13.9586 11.08 14.1356Z"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                )}
              </form>
            </div>
          )}
          <span
            style={{
              width: "56px",
              height: "56px",
              boxShadow: "0px 4px 20px 0px #00000026",
            }}
            onClick={() => setShowChat(true)}
            className="d-flex d-align-center d-justify-center bg-sea-green rounded-50 cursor-pointer"
          >
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M32.3338 30C32.9729 28.7499 33.3333 27.3337 33.3333 25.8333C33.3333 20.7707 29.2293 16.6667 24.1667 16.6667C19.1041 16.6667 15 20.7707 15 25.8333C15 30.8959 19.1041 35 24.1667 35L35 35C35 35 33.3333 33.3333 32.3572 30.0486M31.4166 20C31.5806 19.1922 31.6667 18.3562 31.6667 17.5C31.6667 10.5964 26.0702 5 19.1667 5C12.2631 5 6.66667 10.5964 6.66667 17.5C6.66667 18.961 6.91731 20.3634 7.37794 21.6667C9.1683 26.6862 5 30 5 30H15.8333"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default ChatBot;