import React from 'react'
import styles from "../../styles/modular css/ToolTip.module.css"

function ToolTip({ children, text, embedClass, position, style }) {
  return (
    <div className={`${styles.tooltip} ${embedClass}`}>
      {children}
      <span
        style={style}
        className={`h6 ${styles.tooltiptext} ${
          position == "bottom" ? styles.tooltiptextBottom : styles.tooltiptextUp
        }`}
        dangerouslySetInnerHTML={{ __html: text }}
      >
      </span>
    </div>
  );
}

export default ToolTip