import React, { useEffect, useState } from "react";
import { getMethod } from "../../utils/apiMethod";
import { toastError } from "../../utils/Toasters";
import Loader from "../general/Loader";
import { useContext } from "react";
import { StateContext } from "../Context/StateContext";

function Privacy({ companyId }) {
  const { setLoading } = useContext(StateContext);
  const [content, setContent] = useState("");
  const [url, setUrl] = useState("");
  useEffect(() => {
    setLoading(true);
    getMethod(`users/getCompanyPrivacy/${companyId}`)
      .then((res) => {
        if (res?.status) {
          setContent(res?.data?.content);
          setUrl(res?.data?.url || res?.data?.pdfUrl);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
      });
  }, []);

  return (
    <div className="d-flex d-flex-column bg-light-blue rounded-16 p-6 gap-2">
      {/* Heading text */}
      <div>
        <h1 className="text-blue font-20">Privacy</h1>
        {content ? (
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        ) : (
          <div className="w-full flex justify-center">
            <em>No policy added yet</em>
          </div>
        )}
        {url && (
          <span className="text-grey-9">
            For detailed Privacy Policy, click {` `}
            <a className="text-blue f-500" href={url} target="_blank">
              here
            </a>
          </span>
        )}
      </div>
    </div>
  );
}

export default Privacy;
