import React, { useState } from "react";

function TableHeadings({
  sorting,
  title,
  filterType,
  filterKey,
  claimRequests,
  setClaimRequests,
}) {
  const [sortAsc, setSortAsc] = useState(false);

const sortHandler = () => {
  let sortedArr = [...claimRequests].sort((a, b) => {
    let eleA;
    let eleB;
    if (filterType === "companyName") {
      eleA = a.companyDetail.name.toUpperCase(); // Convert to uppercase to ensure case-insensitive sorting
      eleB = b.companyDetail.name.toUpperCase();
    }
    if (filterType === "date") {
      eleA = a[filterKey];
      eleB = b[filterKey];
    }
    if (filterType === "text") {
      eleA = a[filterKey]?.toUpperCase();
      eleB = b[filterKey]?.toUpperCase();
    }

    if (eleA < eleB) {
      return sortAsc ? 1 : -1;
    }
    if (eleA > eleB) {
      return sortAsc ? -1 : 1;
    }
    return 0; // Names or dates are equal
  });

  setSortAsc((prev) => !prev);
  setClaimRequests([...sortedArr]); // Update with a new array reference
};


  return (
    <th align="left">
      <div className={`d-flex d-align-center`}>
        {title}
        {sorting && (
          <svg
            className="cursor-pointer"
            onClick={sortHandler}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.83334 12.4999L10 16.6666L14.1667 12.4999M5.83334 7.49992L10 3.33325L14.1667 7.49992"
              stroke="#1CBAAB"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        )}
      </div>
    </th>
  );
}

export default TableHeadings;
