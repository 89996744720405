import React, { useState, useEffect, useRef } from "react";
import styles from "../../../styles/modular css/AdvancedFilterModal.module.css";
import { toastInfo } from "../../../utils/Toasters";
import { RangeSlider } from "../RangeSlider";
import FilterDropdown from "./FilterDropdown";

function useOutsideAlerter(ref, handler) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, handler]);
}

function AdvancedFilterModal({ handler, defaultFilters }) {
  const [open, setOpen] = useState(false);
  const [companyFounded, setCompanyFounded] = useState(
    defaultFilters?.companyFounded
  );
  const [customDate, setCustomDate] = useState(defaultFilters?.customDate);
  const [employees, setEmployees] = useState(defaultFilters?.employees);
  const [ipoStatus, setIpoStatus] = useState(defaultFilters?.ipoStatus);
  const [annualRevenue, setAnnualRevenue] = useState(
    defaultFilters?.annualRevenue
  );
  const [funding, setFunding] = useState(defaultFilters?.funding);
  const [valuation, setValuation] = useState(defaultFilters?.valuation);
  const [totalFundingRaised, setTotalFundingRaised] = useState(
    defaultFilters?.totalFundingRaised
  );

  const wrapperRef = useRef(null);
  const openHandler = () => setOpen((prev) => !prev);
  useOutsideAlerter(wrapperRef, openHandler);

  const companyFoundedArr = [
    {
      text: "None",
      val: "",
    },
    {
      text: "Last 30 days",
      val: "Last 30 days",
    },
    {
      text: "Last 60 days",
      val: "Last 60 days",
    },
    {
      text: "Last 90 days",
      val: "Last 90 days",
    },
    {
      text: "This year",
      val: "This year",
    },
    {
      text: "Last year",
      val: "Last year",
    },
    {
      text: "Custom range",
      val: "Custom range",
    },
  ];

  const employeesNumberArr = [
    {
      text: "0-10",
      val: "0-10",
    },
    {
      text: "10-25",
      val: "10-25",
    },
    {
      text: "25-50",
      val: "25-50",
    },
    {
      text: "50-100",
      val: "50-100",
    },
    {
      text: "100-200",
      val: "100-200",
    },
    {
      text: "200-500",
      val: "200-500",
    },
    {
      text: "500-1000",
      val: "500-1000",
    },
    {
      text: "1000-2000",
      val: "1000-2000",
    },
    {
      text: "2000-5000",
      val: "2000-5000",
    },
    {
      text: "5000-10000",
      val: "5000-10000",
    },
    {
      text: "10000+",
      val: "10000+",
    },
  ];

  const ipoStatusArr = [
    {
      text: "None",
      val: "",
    },
    {
      text: "Public",
      val: "public",
    },
    {
      text: "Private",
      val: "private",
    },
    {
      text: "Other",
      val: "others",
    },
  ];

  const fundingArr = [
    {
      text: "Self Funded/Angel",
      val: "self-funded/angel",
    },
    {
      text: "Pre-Seed",
      val: "pre-seed",
    },
    {
      text: "Seed",
      val: "seed",
    },
    {
      text: "Series A",
      val: "series A",
    },
    {
      text: "Series B",
      val: "series B",
    },
    {
      text: "Series C",
      val: "series C",
    },
    {
      text: "Series D",
      val: "series D",
    },
    {
      text: "Series E",
      val: "series E",
    },
    {
      text: "Series F",
      val: "series F",
    },
    {
      text: "Other",
      val: "other",
    },
  ];

  const annualRevenueArr = [
    {
      text: "Less than $1M",
      val: "Less than $1M",
    },
    {
      text: "$1M - $10M",
      val: "$1M - $10M",
    },
    {
      text: "$10M - $50M",
      val: "$10M - $50M",
    },
    {
      text: "$50M - $100M",
      val: "$50M - $100M",
    },
    {
      text: "$100M - $250M",
      val: "$100M - $250M",
    },
    {
      text: "$250M - $500M",
      val: "$250M - $500M",
    },
    {
      text: "$500M - $1B",
      val: "$500M - $1B",
    },
    {
      text: "$1B - $10B",
      val: "$1B - $10B",
    },
    {
      text: "$10B+",
      val: "$10B+",
    },
  ];

  const submitHandler = (e) => {
    e.preventDefault();
    var obj = {
      companyFounded: companyFounded,
      customDate: customDate,
      employees: employees,
      ipoStatus: ipoStatus,
      annualRevenue: annualRevenue,
      funding: funding,
      valuation: valuation,
      totalFundingRaised: totalFundingRaised,
    };
    handler(obj);
    setOpen(false);
  };

  useEffect(()=>{
    setCompanyFounded(defaultFilters?.companyFounded);
    setCustomDate(defaultFilters?.customDate);
    setEmployees(defaultFilters?.employees);
    setIpoStatus(defaultFilters?.ipoStatus);
    setAnnualRevenue(defaultFilters?.annualRevenue);
    setFunding(defaultFilters?.funding);
    setValuation(defaultFilters?.valuation);
    setTotalFundingRaised(defaultFilters?.totalFundingRaised);
  },[defaultFilters])

  const resetHandler = (e) => {
    var obj = {
      companyFounded: {
        text: "None",
        val: "",
      },
      customDate: {
        from: null,
        to: null,
      },
      employees: [],
      ipoStatus: {
        text: "None",
        val: "",
      },
      annualRevenue: [],
      funding: [],
      valuation: [0, 10000000000],
      totalFundingRaised: [0, 10000000000],
    };
    handler(obj);
  }

  const addHandler = (val,arr, setArr) => {
    // Check if an object with the same `val` already exists in the array
    const exists = arr.some((item) => item.val === val.val);

    if (!exists) {
      setArr([...arr, val]); // Add the object if it doesn't exist
    } else {
      toastInfo("Already Exists."); // Show toast message if it exists
    }
  }

  const deleteHandler = (arr, setArr, ind) => {
    let newArr = arr.filter((item, index) => index != ind);
    setArr(newArr)
  }
  return (
    <div className={`${styles.modalWrapper} p-relative d-flex d-justify-end`}>
      <div
        onClick={openHandler}
        className={`${styles.filterWrapper} p-relative d-flex d-align-center d-justify-center p-4`}
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M34.999 9.99996H31.6657M34.999 20H26.6657M34.999 30H26.6657M11.6657 33.3333V22.602C11.6657 22.2553 11.6657 22.082 11.6317 21.9162C11.6015 21.7691 11.5515 21.6267 11.4832 21.493C11.4062 21.3423 11.2979 21.2069 11.0813 20.9362L5.58337 14.0637C5.36679 13.793 5.2585 13.6576 5.1815 13.5069C5.11318 13.3732 5.06324 13.2308 5.03305 13.0837C4.99902 12.9179 4.99902 12.7446 4.99902 12.3979V9.33329C4.99902 8.39987 4.99902 7.93316 5.18068 7.57664C5.34047 7.26304 5.59544 7.00807 5.90904 6.84828C6.26556 6.66663 6.73227 6.66663 7.66569 6.66663H22.3324C23.2658 6.66663 23.7325 6.66663 24.089 6.84828C24.4026 7.00807 24.6576 7.26304 24.8174 7.57664C24.999 7.93316 24.999 8.39987 24.999 9.33329V12.3979C24.999 12.7446 24.999 12.9179 24.965 13.0837C24.9348 13.2308 24.8849 13.3732 24.8166 13.5069C24.7395 13.6576 24.6313 13.793 24.4147 14.0637L18.9167 20.9362C18.7001 21.2069 18.5918 21.3423 18.5148 21.493C18.4465 21.6267 18.3966 21.7691 18.3664 21.9162C18.3324 22.082 18.3324 22.2553 18.3324 22.602V28.3333L11.6657 33.3333Z"
            stroke="#A39C9C"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span className="p-absolute bg-sea-green rounded"></span>
      </div>
      {open && (
        <div
          ref={wrapperRef}
          className={`${styles.popUp} p-absolute d-flex d-flex-column gap-2 p-5 rounded-5 bg-white box-shadow1`}
        >
          <span className="h3 f-700 text-black">Advanced Filters</span>
          <div className="d-flex d-flex-column gap-3">
            <div className="d-flex d-flex-column gap-1">
              <span className="h4 f-500 l-27">Overview</span>
              <div className="d-flex d-flex-column gap-1">
                <div className="d-flex d-flex-column">
                  <span className="h6 f-400 l-22 text-black">
                    Company Founded
                  </span>
                  <FilterDropdown
                    arr={companyFoundedArr}
                    defaultVal={companyFounded}
                    defaultDate={customDate}
                    handler={(val) => setCompanyFounded(val)}
                    customDateHandler={(val) => setCustomDate(val)}
                  />
                </div>
                <div className="d-flex d-flex-column">
                  <span className="h6 f-400 l-22 text-black">
                    No. of employees
                  </span>
                  <FilterDropdown
                    arr={employeesNumberArr}
                    defaultVal={employees}
                    handler={(val) => {
                      addHandler(val, employees, setEmployees);
                    }}
                    deleteHandler={(ind) => {
                      deleteHandler(employees, setEmployees, ind);
                    }}
                    multiselector={true}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex d-flex-column gap-1">
              <span className="h4 f-500 l-27">Financials</span>
              <div className="d-flex d-flex-column gap-1">
                <div className="d-flex d-flex-column">
                  <span className="h6 f-400 l-22 text-black">IPO Status</span>
                  <FilterDropdown
                    arr={ipoStatusArr}
                    defaultVal={ipoStatus}
                    handler={(val) => setIpoStatus(val)}
                  />
                </div>
                <div className="d-flex d-flex-column">
                  <span className="h6 f-400 l-22 text-black">
                    Annual Revenue
                  </span>
                  <FilterDropdown
                    arr={annualRevenueArr}
                    defaultVal={annualRevenue}
                    handler={(val) => {
                      addHandler(val, annualRevenue, setAnnualRevenue);
                    }}
                    deleteHandler={(ind) => {
                      deleteHandler(annualRevenue, setAnnualRevenue, ind);
                    }}
                    multiselector={true}
                  />
                </div>
                <div className="d-flex d-flex-column">
                  <span className="h6 f-400 l-22 text-black">Funding</span>
                  <FilterDropdown
                    // arr={fundingArr}
                    // defaultVal={funding}
                    // handler={(val) => setFunding(val)}
                    arr={fundingArr}
                    defaultVal={funding}
                    handler={(val) => {
                      if(val?.val=="other"){ setFunding({
                        text: "Other",
                        val: "other",
                      })}
                      else {
                        if (Array.isArray(funding)){
                          addHandler(val, funding, setFunding);
                        } else{
                          addHandler(val, [], setFunding);
                        }
                      }
                    }}
                    deleteHandler={(ind) => {
                      deleteHandler(funding, setFunding, ind);
                    }}
                    multiselector={`${funding?.val=="other"?false:true}`}
                  />
                </div>
                <div className="d-flex d-flex-column">
                  <span className="h6 f-400 l-22 text-black">Valuation </span>
                  {valuation && (
                    <RangeSlider
                      handler={(val) => setValuation(val)}
                      defaultVal={valuation}
                    />
                  )}
                </div>
                <div className="d-flex d-flex-column">
                  <span className="h6 f-400 l-22 text-black">
                    Total Funding Raised
                  </span>
                  {totalFundingRaised && (
                    <RangeSlider
                      handler={(val) => setTotalFundingRaised(val)}
                      defaultVal={totalFundingRaised}
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className={`${styles.btnWrapper} col-12 d-flex d-justify-end gap-2`}
            >
              <div
                className="d-flex d-align-center d-justify-center"
                role="button"
                onClick={resetHandler}
              >
                Reset
              </div>
              <div
                className="d-flex d-align-center d-justify-center"
                role="button"
                onClick={submitHandler}
              >
                Apply
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdvancedFilterModal;
